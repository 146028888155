'use strict';

(function () {
  class BatchImportModalCtrl {
    constructor($scope, $http, $uibModalInstance, $state, Upload, Image, Finding, Repair, Blade, options,
      usSpinnerService, toastr, Util, Auth, $timeout, ActivityLog) {
      this.Util = Util;
      this.$http = $http
      this.$scope = $scope;
      this.Blade = Blade;
      this.Finding = Finding;
      this.Repair = Repair;
      this.Upload = Upload;
      this.Image = Image;
      this.toastr = toastr;
      this.usSpinnerService = usSpinnerService;
      this.$uibModalInstance = $uibModalInstance;
      this.$timeout = $timeout;
      this.isDisabled = false;
      this.options = options;
      this.submitted = false;
      this.ActivityLog = ActivityLog;
      Auth.isLoggedInAsync((loggedIn) => {
        if (loggedIn) {
          this.currentUser = Auth.getCurrentUser();
        } else {
          $state.go('login');
        }
      });

      this.validImagesData = [];
      this.invalidImageFiles = [];
    }

    startExport() {
      this.importStarted = true;
      this.currentFileCount = 0;
      this.skippedFiles = [];
      this.newFindingFiles = [];
      this.newRepairFiles = [];
      this.imageAddedToFindingFiles = [];
      this.imageAddedToRepairFiles = [];
      async.eachSeries(this.validImagesData, (dataObj, callback) => {
        // Perform operation on file here.
        // console.log('Processing file ', dataObj.finding.fileName);
        this.currentFileCount++;
        if (dataObj.importType === 'finding') {
          // first check duplicate else start upload
          dataObj.onlyCheckDuplicates = true;
          this.Finding.importFindingImage(dataObj).$promise
            .then(result => {
              console.log('check dup result: ', result.isDuplicate);
              // return;
              if (!dataObj.finding.images) {
                dataObj.finding.images = [];
              }
              // upload image & then create finding
              const imageFile = _.find(this.imageFiles, { name: dataObj.finding.fileName });
              console.log('in upload image');
              var woId = "";
              if (dataObj.finding.images.length > 0) {
                var fileTokens = dataObj.finding.images[0].imagePath.split("/");
                woId = fileTokens[fileTokens.length - 2];
              }
              this.uploadImage(imageFile, woId, (err, filePath) => {
                if (!err) {
                  if (!result.isDuplicate) {
                    dataObj.finding.images.push(filePath);
                    // send create finding req
                    dataObj.onlyCheckDuplicates = false;
                    this.Finding.importFindingImage(dataObj).$promise
                      .then(result => {
                        console.log('import finding image done: ', result);
                        this.newFindingFiles.push(dataObj.finding.fileName);
                        var targetBlade = _.find(dataObj.turbine.blades, { id: dataObj.finding.bladeId });
                        const activityLogData = {
                          site: this.options.site._id,
                          turbine: result.finding.turbine,
                          blade: result.finding.blade,
                          user: this.currentUser._id,
                          source: 'bulk-import',
                          action: 'create',
                          type: 'finding',
                          sourceScreen: 'site',
                          eventString: `Finding <a href="/blade/${result.finding.blade}/finding/${result.finding._id}">${result.finding.uniqueReferenceNumber}</a> was created in wind farm <a href="/site/${this.options.site._id}">${this.options.site.name}</a>, turbine <a href="/turbine/${result.finding.turbine}">${this.getTurbineStr(dataObj.turbine)}</a>, blade <a href="/blade/${result.finding.blade}">${this.getBladeStr(targetBlade)}</a> by ${this.currentUser.name} from bulk import.`
                        }

                        this.ActivityLog.save(activityLogData).$promise
                          .then(activityLog => {
                            console.log(activityLog);
                          })
                          .catch(err => {
                            console.log('Error inserting activityLog', err);
                          });
                        callback();
                      }, err => {
                        this.skippedFiles.push(dataObj);
                        console.log('import finding failed: ', err);
                        this.toastr.error('Something went wrong while importing finding image. trying next image.');
                        callback();
                      });
                  } else {
                    // this.skippedFiles.push(dataObj);
                    console.log('adding image to existing finding: ', dataObj.finding);

                    this.Finding.addImage({ id: result.duplicateFinding._id }, { image: filePath }).$promise
                      .then(response => {
                        // this.toastr.success('Image added to finding');
                        this.imageAddedToFindingFiles.push(dataObj.finding.fileName);
                        var targetBlade = _.find(dataObj.turbine.blades, { id: dataObj.finding.bladeId });
                        const activityLogData = {
                          site: this.options.site._id,
                          turbine: response.data.turbine,
                          blade: response.data.blade,
                          user: this.currentUser._id,
                          source: 'bulk-import',
                          action: 'edit',
                          type: 'finding',
                          sourceScreen: 'site',
                          eventString: `Finding <a href="/blade/${response.data.blade}/finding/${response.data._id}">${response.data.uniqueReferenceNumber}</a> was edited in wind farm <a href="/site/${this.options.site._id}">${this.options.site.name}</a>, turbine <a href="/turbine/${response.data.turbine}">${this.getTurbineStr(dataObj.turbine)}</a> blade <a href="/blade/${response.data.blade}">${this.getBladeStr(targetBlade)}</a> by ${this.currentUser.name} from bulk import.`
                        }

                        this.ActivityLog.save(activityLogData).$promise
                          .then(activityLog => {
                            console.log(activityLog);
                          })
                          .catch(err => {
                            console.log('Error inserting activityLog', err);
                          });
                        callback();
                      })
                      .catch(err => {
                        console.log('Err', err);
                        this.toastr.error('Something went wrong while adding image to finding. trying next image.');
                        this.skippedFiles.push(dataObj);
                        callback();
                      });
                  }

                } else {
                  this.skippedFiles.push(dataObj);
                  console.log('upload image failed:', err);
                  this.toastr.error('Something went wrong while image uploading. trying next image.');
                  callback();
                }
              });
            }).catch(err => {
              this.skippedFiles.push(dataObj);
              console.log('check dup failed : ', err);
              this.toastr.error('Something went wrong while checking duplicate. trying next image.');
              callback();
            });
        } else if (dataObj.importType === 'repair') {
          // this.skippedFiles.push(dataObj);
          // first check duplicate else start upload
          dataObj.onlyCheckDuplicates = true;
          this.Repair.importRepairImage(dataObj).$promise
            .then(result => {
              console.log('check dup result: ', result.isDuplicate);
              // return;
              if (!dataObj.repair.images) {
                dataObj.repair.images = [];
              }
              // upload image & then create repair
              const imageFile = _.find(this.imageFiles, { name: dataObj.repair.fileName });
              console.log('in upload image');
              var woId = "";
              // uncomment this after app updates with s3 file path on images
              // if (dataObj.repair.images.length > 0) {
              //   var fileTokens = dataObj.repair.images[0].imagePath.split("/");
              //   woId = fileTokens[fileTokens.length - 2];
              // }
              this.uploadImage(imageFile, woId, (err, filePath) => {
                if (!err) {
                  if (!result.isDuplicate) {
                    let sectionName = this.getImageUploadSectionName(dataObj.repair, dataObj.repair.fileName);
                    console.log('sectionName', sectionName);
                    if (sectionName === '') {
                      dataObj.repair['images'].push({
                        path: filePath,
                        isPrimary: true
                      });
                    } else {
                      if (sectionName == 'images' || sectionName == 'closeUpImages') {
                        dataObj.repair[sectionName].push({
                          path: filePath,
                          isPrimary: true
                        });
                      } else {
                        let conditionIndex = -1;
                        conditionIndex = _.findIndex(dataObj.repair.defectProcedure.conditions, function (condition) {
                          return condition.name == sectionName;
                        });
                        if (conditionIndex >= 0) {
                          dataObj.repair.defectProcedure.conditions[conditionIndex]['images'].push({
                            path: filePath,
                            isPrimary: true
                          });
                        } else {
                          dataObj.repair['images'].push({
                            path: filePath,
                            isPrimary: true
                          });
                        }
                      }
                    }
                    // send create repair req
                    dataObj.onlyCheckDuplicates = false;
                    this.Repair.importRepairImage(dataObj).$promise
                      .then(result => {
                        this.newRepairFiles.push(dataObj.repair.fileName);
                        console.log('import repair image done: ', result);
                        var targetBlade = _.find(dataObj.turbine.blades, { id: dataObj.repair.bladeId });
                        const activityLogData = {
                          site: this.options.site._id,
                          turbine: result.repair.turbine,
                          blade: result.repair.blade,
                          user: this.currentUser._id,
                          source: 'bulk-import',
                          action: 'create',
                          type: 'repair',
                          sourceScreen: 'site',
                          eventString: `Repair was created for technician ${dataObj.technicianId} in wind farm <a href="/site/${this.options.site._id}">${this.options.site.name}</a>, turbine <a href="/turbine/${result.repair.turbine}">${this.getTurbineStr(dataObj.turbine)}</a>, blade <a href="/blade/${result.repair.blade}">${this.getBladeStr(targetBlade)}</a> by ${this.currentUser.name} from bulk import.`
                        }

                        this.ActivityLog.save(activityLogData).$promise
                          .then(activityLog => {
                            console.log(activityLog);
                          })
                          .catch(err => {
                            console.log('Error inserting activityLog', err);
                          });
                        callback();
                      }, err => {
                        console.log('import repair failed: ', err);
                        this.skippedFiles.push(dataObj);
                        this.toastr.error((err.data && err.data.msg) || 'Something went wrong while importing repair image. please try again later.');
                        callback();
                      });
                  } else {
                    console.log('skipping duplicate repair: ', dataObj.repair);
                    let sectionName;
                    sectionName = this.getImageUploadSectionName(dataObj.repair, dataObj.repair.fileName);
                    if (sectionName == '') {
                      sectionName = this.getImageUploadSectionName(result.duplicateRepair, dataObj.repair.fileName);
                    }
                    let conditionIndex = -1;
                    if (sectionName != '' && sectionName != 'images' && sectionName != 'closeUpImages') {
                      conditionIndex = _.findIndex(result.duplicateRepair.defectProcedure.conditions, function (condition) {
                        return condition.name == sectionName;
                      });
                      if (conditionIndex < 0) {
                        sectionName = 'images';
                      }
                    }
                    console.log('sectionName', sectionName);
                    console.log('conditionIndex', conditionIndex);
                    this.Repair.addImage({ id: result.duplicateRepair._id }, { image: filePath, sectionName: sectionName, conditionIndex: conditionIndex }).$promise
                      .then(response => {
                        // this.toastr.success('Image added to repair');
                        this.imageAddedToRepairFiles.push(dataObj.repair.fileName);
                        var targetBlade = _.find(dataObj.turbine.blades, { id: dataObj.repair.bladeId });
                        const activityLogData = {
                          site: this.options.site._id,
                          turbine: response.data.turbine,
                          blade: response.data.blade,
                          user: this.currentUser._id,
                          source: 'bulk-import',
                          action: 'edit',
                          type: 'repair',
                          sourceScreen: 'site',
                          eventString: `Repair was edited for technician ${dataObj.technicianId} in wind farm <a href="/site/${this.options.site._id}">${this.options.site.name}</a>, turbine <a href="/turbine/${response.data.turbine}">${this.getTurbineStr(dataObj.turbine)}</a>, blade <a href="/blade/${response.data.blade}">${this.getBladeStr(targetBlade)}</a> by ${this.currentUser.name} from bulk import.`
                        }

                        this.ActivityLog.save(activityLogData).$promise
                          .then(activityLog => {
                            console.log(activityLog);
                          })
                          .catch(err => {
                            console.log('Error inserting activityLog', err);
                          });
                        callback();
                      })
                      .catch(err => {
                        this.skippedFiles.push(dataObj);
                        console.log('Err', err);
                        this.toastr.error((err.data && err.data.msg) || 'Something went wrong while adding image to repair. please try again later.');
                        callback();
                      });
                  }

                } else {
                  this.skippedFiles.push(dataObj);
                  console.log('upload image failed:', err);
                  this.toastr.error((err.data && err.data.msg) || 'Something went wrong while image uploading. please try again later.');
                  callback();
                }
              });
            }).catch(err => {
              this.skippedFiles.push(dataObj);
              console.log('check dup failed : ', err);
              this.toastr.error((err.data && err.data.msg) || 'Something went wrong while checking duplicate. please try again later.');
              callback();
            });
        }
      }, err => {
        // if any of the file processing produced an error, err would equal that error
        if (err) {
          // One of the iterations produced an error.
          // All processing will now stop.
          console.log('A file failed to process');
        } else {
          const msg = this.skippedFiles.length > 0 ? `Valid Images Imported Successfully ${this.skippedFiles.length} duplicate findings skipped.` : 'Valid Images Imported Successfully.'
          this.toastr.success(msg);
          this.importDone = true;
          // this.$uibModalInstance.close(true);
        }
      });
    }

    getImageUploadSectionName(repair, fileName) {
      // find in images section
      let imageResult = _.find(repair.images, function (image) {
        return image.path.indexOf(fileName) > -1;
      });
      if (imageResult) {
        return 'images';
      }
      // find in closeUpImages section
      let closeupImageResult = _.find(repair.closeUpImages, function (image) {
        return image.path.indexOf(fileName) > -1;
      });
      if (closeupImageResult) {
        return 'closeUpImages';
      }
      // find in defectProcedure's conditions section
      let conditionResultName = '';
      for (let i = 0; i < repair.defectProcedure.conditions.length; i++) {
        let condition = repair.defectProcedure.conditions[i];
        let conditionResult = _.find(condition.images, function (image) {
          if (image.imagePath) {
            return image.imagePath.indexOf(fileName) > -1;
          }
        });
        if (conditionResult) {
          conditionResultName = condition.name;
          break;
        }
      }
      if (!conditionResultName && fileName.match(/^rc_/)) {
        conditionResultName = 'closeUpImages';
      }
      return conditionResultName;
    }

    getTurbineStr(turbine) {
      if (!turbine) { return 'NA' };
      let turbineStr = '';
      if (turbine.make) {
        turbineStr += turbine.make;
      }
      if (turbine.model) {
        turbineStr += turbine.model;
      }
      if (turbine.name) {
        turbineStr += turbine.name;
      }
      return turbineStr;
    }

    getBladeStr(blade) {
      if (!blade) { return 'NA' };
      let bladeStr = '';
      if (blade.make) {
        bladeStr += blade.make + ' ';
      }
      if (blade.model) {
        bladeStr += blade.model;
      }
      if (blade.name) {
        bladeStr += blade.name;
      }
      return bladeStr;
    }

    imagesSelected(files) {
      console.log('imagesSelected : ', files);
      this.isReadingExif = true;
      let processedFiles = 0;
      for (let index = 0; index < files.length; index++) {
        const element = files[index];
        this._findExif(element, err => {
          if (++processedFiles === files.length) {
            this.$timeout(() => {
              this.isReadingExif = false;
              this.isImagesScanned = true;
              console.log('valid images data: ', this.validImagesData);
              console.log('invalid files: ', this.invalidImageFiles);
            }, 10, true);
          }
        });
      }
    }

    removeImage(index) {
      this.finding.images.splice(index, 1);
    }

    uploadImage(file, woId, cb) {
      if (!file || !file.name) {
        cb({ success: false });
      }
      //console.log('image file:  ', file);
      var filename = file.name;
      var type = file.type;
      var query = {
        filename: filename,
        woId: woId,
        type: type
      };
      this.isUploading = true;
      this.Image.signing({}, query).$promise
        .then(result => {
          this.Upload.upload({
            url: result.url, //s3Url
            transformRequest: function (data, headersGetter) {
              var headers = headersGetter();
              delete headers.Authorization;
              return data;
            },
            fields: result.fields, //credentials
            method: 'POST',
            file: file
          }).progress((evt) => {
            this.uploadProgress = parseInt(100.0 * evt.loaded / evt.total);
            // console.log('progress: ' + parseInt(100.0 * evt.loaded / evt.total));
          }).success((data, status, headers, config) => {
            this.isUploading = false;
            // file is uploaded successfully
            // console.log('file ' + config.file.name + 'is uploaded successfully. Response: ' + data);
            cb(null, result.url + '/' + result.fields.key);
          }).error((err) => {
            this.isUploading = false;
            console.log('err', err);
            this.toastr.success('Image was not uploaded to cloud. please try again later or contact administrator');
            cb(err);
          });
        });
    }

    _findExif(file, cb) {
      var reader = new FileReader();
      reader.onload = event => {
        try {
          var tags = ExifReader.load(event.target.result);
          // The MakerNote tag can be really large. Remove it to lower
          // memory usage if you're parsing a lot of files and saving the
          // tags.
          delete tags['MakerNote'];
          // console.log('raw data: ', JSON.stringify(tags));
          // var imageData = null;
          // _.each(tags, function(val, key) {
          //   val = JSON.parse(val.value[0] || val.value);
          //   if(val.length && val.length > 0 && val[0].data.finding && val[0].data.turbine) {
          //     imageData = JSON.parse(JSON.stringify(val[0]));
          //   } else if(val.data && val.data.finding && val.data.turbine) {
          //     imageData = JSON.parse(JSON.stringify(val));
          //   }
          // })
          if (tags['undefined-37510'] || tags['UserComment']) {
            var val = tags['undefined-37510'] ? tags['undefined-37510'].value : tags['UserComment'].value;
            var imageData = JSON.parse(val);
            // console.log('image data: ', imageData);
            if (imageData.data) {
              // ########## add default technician id if missing in exif data 
              if (imageData.data && imageData.data.repair && !imageData.data.technician) {
                imageData.data.technician = {
                  id: '1234AB' // default technician id if missing
                }
              }
              if (imageData.data.finding) {
                const finding = imageData.data.finding;
                // console.log('imageData.data.finding.extentOfDamage : ', imageData.data.finding.extentOfDamage);
                finding.inspectionType = imageData.data.finding.type.toLowerCase();
                finding.extentOfDamage = imageData.data.finding.extentOfDamage.toLowerCase();
                finding.fileName = file.name;
                const turbine = imageData.data.turbine;
                turbine.site = this.options.site._id;
                this.validImagesData.push({ finding: finding, turbine: imageData.data.turbine, importType: 'finding' });
                // console.log('new finding: ', finding);
                cb();
              } else if (imageData.data.repair && imageData.data.turbine && imageData.data.technician && imageData.data.technician.id) {
                const repair = imageData.data.repair;
                repair.closeUpImages = imageData.data.repair.closeUp.map(obj => {
                  obj.path = obj.imagePath;
                  delete obj.imagePath;
                  return obj;
                });
                repair.images = imageData.data.repair.photos.map(obj => {
                  obj.path = obj.imagePath;
                  delete obj.imagePath;
                  return obj;
                });
                repair.numberOfDefects = imageData.data.repair.defects;
                repair.locationInBlade = imageData.data.repair.location;
                repair.chordDistance = imageData.data.repair.chord;
                repair.fileName = file.name;
                repair.defectProcedure.conditions.map((condition) => {
                  condition.ambientTemperature = condition.photo.ambientTemperature;
                  condition.curingTemperature = condition.photo.curingTemperature;
                  condition.humidity = condition.photo.humidity;
                  condition.images = condition.photo.images;
                  delete condition.photo;
                  return condition;
                });
                const turbine = imageData.data.turbine;
                turbine.site = this.options.site._id;
                this.validImagesData.push({ repair: repair, turbine: imageData.data.turbine, technicianId: imageData.data.technician.id, importType: 'repair' });
                cb();
              } else {
                console.log('Unexpected Data Received');
                throw { code: 'MISSING_DATA' };
              }
            } else {
              this.invalidImageFiles.push(file.name);
              cb({ code: 'MISSING_DATA' });
              // console.log('data not found');
            }
            // console.log('data: ', imageData);
          } else {
            this.invalidImageFiles.push(file.name);
            cb({ code: 'MISSING_DATA' });
            console.log('data not found for file');
          }
        } catch (error) {
          this.invalidImageFiles.push(file.name);
          cb(error);
          console.log('read exif failed: ', error);
        }
      };
      // We only need the start of the file for the Exif info.
      reader.readAsArrayBuffer(file.slice(0, 128 * 1024));
      //--reader.readAsDataURL(file);
    }

    cancelSave() {
      this.$uibModalInstance.dismiss('cancel');
    }

    startSpin(spinner) {
      this.usSpinnerService.spin(spinner);
    }

    stopSpin(spinner) {
      this.usSpinnerService.stop(spinner);
    }

  

  }

  angular.module('windmanagerApp')
    .controller('BatchImportModalCtrl', BatchImportModalCtrl);
})();
